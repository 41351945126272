import Heading from "Components/Heading";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { MdDateRange } from "react-icons/md";
import { BiSearch, BiExport, BiLike } from "react-icons/bi";
import StationCodesAPIs from "../../APIs/station-code";
import { Formik } from "formik";
import * as Yup from "yup";
import Footer from "Components/Footer";
import SampleDataTable from "Components/DataTables";
import FormCheck from "react-bootstrap/FormCheck"; // Make sure you're importing correctly
import { formatDate } from "Helper/Converters";
import CustomDatePicker from "Components/CustomDatePicker";
import moment from "moment";
import OOCExcelParser from "./excelparser";
import TicketAPIs from "../../APIs/ticket";

const DATE_TYPE_OPTIONS = [
  { value: "", label: "Select" },
  { value: "enteredDate", label: "Entered Date" },
];

const INITIAL_VALUES = {
  stationCodeNames: [],
  dateType: DATE_TYPE_OPTIONS[2],
  fromDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
  toDate: new Date(),
  // nofDays: 5,
};

const ValidationSchema = Yup.object().shape({
  dateType: Yup.object()
    .shape({
      value: Yup.string().required("Date Type is required"),
    })
    .required("Date Type is required"),
  fromDate: Yup.date().required("Start Date is required"),
  toDate: Yup.date().required("End Date is required"),
  // nofDays: Yup.number().min(1, "Business Days must be atleast 1"),
});

const ON1NonComplaintReport = () => {
  const [stationCodes, setStationCodes] = useState([]);
  const [loading, setLoading] = useState(false);

  // total tickets in OOC Uploaded File
  const [oocFileTickets, setOocFileTickets] = useState([]);

  // total tickets in OOC Searched using filters
  const [oocSearchedData, setOocSearchedData] = useState([]);

  // filterd tickets to show in table
  const [filterdOOCToShow, setFilterdOOCToShow] = useState([]);

  // tickets in TCU
  const [tcuTickets, setTcuTickets] = useState([]);

  // tcu percentage
  const [tcuPercentage, setTcuPercentage] = useState(0);

  const [oocPercentage, setOocPercentage] = useState(0);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px", // Set the minimum height as needed
    }),
  };
  const columns = [
    {
      accessor: "ticket_no",
      Header: "Ticket No",
      Cell: ({ row }) => (
        <a
          onClick={(e) => {
            e.preventDefault();
            const url = `/dashboard/ticket-detail/${row.original.ticket_no}`;
            // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
            const windowName = `popUpWindow_${row.original.ticket_no}`;
            const windowSize = "width=800,height=600";
            window.open(url, windowName, windowSize);
          }}
          href={`/dashboard/ticket-detail/${row.original.ticket_no}`}
          rel="noopener noreferrer"
          className={`badge ${
            row.original.ticketType == "EMERGENCY" ||
            row.original.priority == "EMERGENCY"
              ? "emergency"
              : row.original.ticketType == "MULTIPLE" ||
                row.original.priority == "MULTIPLE"
              ? "multiple"
              : row.original.ticketType == "PRIORITY" ||
                row.original.priority == "PRIORITY"
              ? "priority"
              : row.original.ticketType == "PROJECT WORK" ||
                row.original.priority == "PROJECT WORK"
              ? "projectWork"
              : row.original.ticketType == "STANDARD" ||
                row.original.priority == "STANDARD"
              ? "standard"
              : "default"
          }`}
        >
          {row.original.ticket_no}
        </a>
      ),
    },

    {
      Header: "Entered Date",
      Cell: ({ row }) => (
        <p>{row.original.entereddate ? row.original.entereddate : ""}</p>
      ),
    },
    // {
    //   Header: "Locate Status",
    //   Cell: ({ row }) => <p>{row.original.locateStatus}</p>,
    // },
    {
      Header: "Priority",
      Cell: ({ row }) => <p>{row.original.priority}</p>,
    },
    // {
    //   Header: "Notification Date",
    //   Cell: ({ row }) => (
    //     <p>{row.original.entereddate ? row.original.entereddate : ""}</p>
    //   ),
    // },
    {
      Header: "Renegotiated Date",
      Cell: ({ row }) => (
        <p>
          {row.original.renegotiateddate ? row.original.renegotiateddate : ""}
        </p>
      ),
    },

    {
      Header: "Compliance Status",
      Cell: ({ row }) => (
        <p>
          {row.original.complianceStatus ? row.original.complianceStatus : ""}
        </p>
      ),
    },
    {
      Header: "OOC Closed Date",
      Cell: ({ row }) => (
        <p>{row.original.closeDate ? row.original.closeDate : ""}</p>
      ),
    },
    {
      Header: "Station Code",
      Cell: ({ row }) => (
        <p>{row.original.stationCode ? row.original.stationCode : ""}</p>
      ),
    },
  ];
  const getAllStationCodes = async () => {
    const stationCodes = await StationCodesAPIs.getStationCode();
    if (stationCodes.data?.success) {
      setStationCodes(
        stationCodes.data.data
          ?.filter((item) => item.isFilterable)
          .sort((a, b) => a.name.localeCompare(b.name)) || []
      );
    }
  };

  useEffect(() => {
    getAllStationCodes();
  }, []);

  const handleFileParsed = (data) => {
    setOocFileTickets(data);
  };

  const findAndTransform = (filters, data) => {
    const { stationCodeNames, fromDate, toDate } = filters;
    const fromDateObj = new Date(fromDate);
    const toDateObj = new Date(toDate);

    // Step 1: Filter data based on station codes and date range
    const filteredData = data.filter((item) => {
      const notificationDate = new Date(item["Notification Date"]);

      return (
        (stationCodeNames.length === 0 ||
          stationCodeNames.includes(item["Station Code"])) &&
        notificationDate >= fromDateObj &&
        notificationDate <= toDateObj
      );
    });

    const ticketMap = new Map();

    filteredData.forEach((item) => {
      const ticketNo = item["Request #"];
      const stationCode = item["Station Code"];

      if (!ticketMap.has(ticketNo)) {
        ticketMap.set(ticketNo, {
          ticket_id: "N/A",
          ticket_no: ticketNo,
          digaddress: "N/A",
          contractorname: "N/A",
          renegotiateddate: item["Renegotiated Date"],
          entereddate: item["Notification Date"],
          renegotiatedattempteddate: "N/A",
          completeddate: "N/A",
          worktobegindate: item["Work to Begin Date"],
          originalcompliancedate: "N/A",
          hasticketrescheduledremark: item["Renegotiated Date"] ? 1 : 0,
          precompleteddate: "N/A",
          complianceDate: "N/A",
          nonCompliantReason: "N/A",
          locateStatus: item["Locate Status"],
          complianceStatus: item["Compliance Status"],
          closeDate: item["Closed Date"],
          stationCodes: new Set([stationCode]), // Using Set to avoid duplicate station codes
          priority: item["Priority Text"],
        });
      } else {
        // Merge station codes for existing ticket number
        ticketMap.get(ticketNo).stationCodes.add(stationCode);
      }
    });

    // Step 3: Convert the Map back to an array with merged station codes
    return Array.from(ticketMap.values()).map((item) => ({
      ...item,
      stationCode: Array.from(item.stationCodes).join(", "), // Convert Set to string
    }));
  };

  const calculateTCUPercentage = async (params) => {
    try {
      const search = await TicketAPIs.nonCompliantReport(params);

      if (search.data?.success) {
        const data = search.data.data || [];
        setTcuTickets(data);

        const totalTCUTickets = data.length;
        if (totalTCUTickets === 0) {
          setTcuPercentage(0);
          return 0;
        }

        // Get compliant tickets from TCU
        const compliantTickets = data.filter(
          (item) => !item.nonCompliantReason
        );

        const percentage = (
          (compliantTickets.length / totalTCUTickets) *
          100
        ).toFixed(2);
        setTcuPercentage(percentage);

        return compliantTickets;
      }
      return 0;
    } catch (error) {
      console.error("Error in calculateTCUPercentage:", error);
      return 0;
    }
  };

  // Fix OOC percentage calculation
  const calculateOOCPercentage = (searchedData) => {
    if (!searchedData || searchedData.length === 0) return 0;

    const totalTickets = searchedData.length;
    const compliantTickets = searchedData.filter(
      (item) => item?.complianceStatus === "COMPLIANT"
    ).length;

    const nonCompliantTickets = searchedData.filter(
      (item) => item?.complianceStatus === "NON-COMPLIANT"
    ).length;

    return ((compliantTickets / totalTickets) * 100).toFixed(2);
  };
  const manageTicketsToDisplay = ({ OOCTickets, TCUTickets }) => {
    const toShow = OOCTickets.filter((oocTicket) => {
      return TCUTickets.some(
        (tcuTicket) => tcuTicket.ticket_no === oocTicket.ticket_no
      );
    });

    setFilterdOOCToShow(toShow);
  };
  // Update handleSubmit to calculate both percentages
  const handleSubmit = async (values) => {
    if (!oocFileTickets.length) {
      alert("Upload an OOC file first.");
      return;
    }

    setLoading(true);
    try {
      // Format values and prepare TCU call
      const formattedValues = {
        ...values,
        fromDate: values.fromDate
          ? moment(values.fromDate).format("YYYY-MM-DD")
          : null,
        toDate: values.toDate
          ? moment(values.toDate).format("YYYY-MM-DD")
          : null,
      };

      const valuesForTCUCall = {
        ...formattedValues,
        dateType: values.dateType.value,
        nofDays: "5",
        stationCodeIds: values.stationCodeNames
          .map((name) => stationCodes.find((s) => s.name === name)?.id)
          .filter(Boolean),
      };

      // Calculate TCU percentage

      const TCUTicketsToDisplay = await calculateTCUPercentage(
        valuesForTCUCall
      );
      // Filter and transform OOC data
      const filteredResults = findAndTransform(values, oocFileTickets);
      setOocSearchedData(filteredResults);

      // Calculate and set OOC percentage
      const oocPercent = calculateOOCPercentage(filteredResults);
      setOocPercentage(oocPercent);

      // Show only non-compliant tickets - then in calculateTCUPercentage we will only show those tickets
      //  which are compliant on our system, but non compliant on OOC side.
      const OOCTicketsToDisplay = filteredResults.filter(
        (item) => item.complianceStatus === "NON-COMPLIANT"
      );

      manageTicketsToDisplay({
        OOCTickets: OOCTicketsToDisplay,
        TCUTickets: TCUTicketsToDisplay,
      });
    } catch (error) {
      console.error("Error processing data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section>
        <Heading text={"OOC Non Compliance Report"} />

        <Card>
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              /* and other goodies */
            }) => {
              return (
                <Form className="dashboard-form" onSubmit={handleSubmit}>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Label>Station Codes</Form.Label>
                        <div className="checkboxHolder">
                          {stationCodes.map((item, index) => (
                            <FormCheck
                              key={item.id}
                              type="checkbox"
                              label={item.name}
                              name={`stationCodes-${index}`}
                              checked={values.stationCodeNames.includes(
                                item.name
                              )}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                setFieldValue(
                                  "stationCodeNames",
                                  isChecked
                                    ? [...values.stationCodeNames, item.name]
                                    : values.stationCodeNames.filter(
                                        (name) => name !== item.name
                                      )
                                );
                                // set StationCode Ids
                                setFieldValue(
                                  "stationCodeIds",
                                  isChecked
                                    ? [...values.stationCodeIds, item.id]
                                    : values?.stationCodeIds?.filter(
                                        (id) => id !== item.id
                                      )
                                );
                              }}
                            />
                          ))}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col className="column">
                      <Form.Group className="form-group">
                        <Form.Label>Date Type</Form.Label>
                        <Select
                          name="dateType"
                          options={DATE_TYPE_OPTIONS}
                          styles={customStyles}
                          value={values.dateType}
                          onChange={(val) => setFieldValue("dateType", val)}
                          onBlur={() => setFieldTouched("dateType", true, true)}
                        />
                        {touched.dateType && errors.dateType?.value && (
                          <span className="error-msg">
                            {errors.dateType?.value}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col className="column">
                      <Form.Group className="form-group">
                        <Form.Label>Start Date</Form.Label>
                        <div className="datepicker_box">
                          <CustomDatePicker
                            name="fromDate"
                            selected={values.fromDate}
                            onSelect={(date) => {
                              setFieldValue("fromDate", date);
                              setFieldValue("toDate", null);
                            }}
                            dateFormat="MM/dd/yyyy" // Adjust the date format as needed
                            onBlur={() =>
                              setFieldTouched("fromDate", true, true)
                            }
                          />
                          <span>
                            <MdDateRange />
                          </span>
                        </div>
                        {touched.fromDate && errors.fromDate && (
                          <span className="error-msg">{errors.fromDate}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col className="column">
                      <Form.Group className="form-group">
                        <Form.Label>Date To</Form.Label>
                        <div className="datepicker_box">
                          <CustomDatePicker
                            name="toDate"
                            minDate={values.fromDate}
                            selected={values.toDate}
                            onSelect={(date) => setFieldValue("toDate", date)}
                            dateFormat="MM/dd/yyyy" // Adjust the date format as needed
                            onBlur={() => setFieldTouched("toDate", true, true)}
                          />
                          <span>
                            <MdDateRange />
                          </span>
                        </div>
                        {touched.toDate && errors.toDate && (
                          <span className="error-msg">{errors.toDate}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col
                      md={12}
                      className="d-flex justify-content-between mb-4"
                    >
                      <OOCExcelParser onFileParsed={handleFileParsed} />

                      <Button style={{ minHeight: "44px" }} type="submit">
                        <BiSearch />
                        {loading ? "Loading..." : "Search"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
          <>
            {/* Tickets Count in file */}
            {oocFileTickets.length > 1 && (
              <strong className="mb-2">
                Tickets found in uploaded file: {""}
                <span
                  className="px-1"
                  style={{
                    backgroundColor: "#ec9706",
                    borderRadius: "3px",
                    padding: "3px",
                  }}
                >
                  {oocFileTickets.length}
                </span>
              </strong>
            )}

            {/* OOC Percentage */}
            {/* OOC Percentage */}
            {oocSearchedData.length > 0 && (
              <strong className="mb-2">
                Estimated OCC Compliance:{" "}
                <span
                  style={{
                    backgroundColor: "#ec9706",
                    borderRadius: "3px",
                    padding: "3px",
                  }}
                  className="px-1"
                >
                  {loading ? "Calculating..." : `${oocPercentage}% Compliant`}
                </span>
              </strong>
            )}

            {/* TCU Percentage */}
            {tcuTickets.length > 0 && (
              <strong className="mb-2">
                Estimated TCU Compliance:{" "}
                <span
                  style={{
                    backgroundColor: "#ec9706",
                    borderRadius: "3px",
                    padding: "3px",
                  }}
                  className="px-1"
                >
                  {loading ? "Calculating..." : `${tcuPercentage}% Compliant`}
                </span>
              </strong>
            )}

            {tcuTickets.length > 0 && (
              <strong className="mb-4">
                <span
                  style={{
                    backgroundColor: "#feca57",
                    borderRadius: "3px",
                    fontSize: "13px",
                    padding: "3px",
                  }}
                  className="px-1"
                >
                  Below is the list of tickets that are considered compliant
                  within our system, but are marked as non-compliant on the OOC
                  side.
                </span>
              </strong>
            )}

            <SampleDataTable columns={columns} data={filterdOOCToShow} />
          </>
          <Footer />
        </Card>
      </section>
    </>
  );
};

export default ON1NonComplaintReport;

import React from "react";
import * as XLSX from "xlsx-js-style";

const OOCExcelParser = ({ onFileParsed }) => {
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Get first worksheet
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert to JSON
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: "",
        blankrows: false,
        raw: false, // Prevents numbers being interpreted incorrectly
      });

      // Extract headers from first row
      const headers = jsonData[0];
      const result = [];

      // Function to check if a value is a serial date and convert it
      const convertExcelDate = (value) => {
        if (typeof value === "number") {
          const date = XLSX.SSF.parse_date_code(value);
          if (date) {
            return new Date(
              date.y,
              date.m - 1,
              date.d,
              date.H,
              date.M,
              date.S
            ).toISOString();
          }
        }
        return value;
      };

      // Process remaining rows
      for (let i = 1; i < jsonData.length; i++) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = convertExcelDate(jsonData[i][j]);
        }
        result.push(obj);
      }

      if (onFileParsed) {
        onFileParsed(result);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="mb-3 w-96">
      <label
        htmlFor="formFile"
        className="m-2  inline-block text-neutral-700 dark:text-neutral-200"
      >
        Attaach OOC File
      </label>
      <input
        className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
        id="formFile"
      />
    </div>
  );
};

export default OOCExcelParser;

import { useEffect, useState } from "react";
import TicketAPIs from "../../APIs/ticket";
import StatusCard from "./Indicator";
import Loader from "Components/Loader";

export default function SystemHealthDashboard() {
  const [health, setHealth] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const fetchHealth = async () => {
    let data = {};
    const serviceNameMapping = {
      storage: "Storage",
      database: "Database",
      ftp_cron: "FTP Service",
      aurora_cron: "Aurora Service",
      pkrg_cron: "PKRG Service",
      pkrgAPI: "PKRG API",
      auroraAPI: "Aurora API",
      sftp_connection: "SFTP Connection",
    };
    try {
      setLoading(true);
      setError("");

      const response = await TicketAPIs.systemHealth();

      // Mapping for service names to custom display names

      if (response?.data?.success) {
        // Success response structure
        data = Object.entries(response.data.data.details).reduce(
          (acc, [key, value]) => {
            const customKey = serviceNameMapping[key] || key;
            acc[customKey] = value;
            return acc;
          },
          {}
        );
      }

      setHealth(data);
    } catch (error) {
      const errorsData = error.response.data.errors.details;
      console.log(errorsData);
      data = Object.entries(errorsData).reduce((acc, [key, value]) => {
        const customKey = serviceNameMapping[key] || key;
        acc[customKey] = value;
        return acc;
      }, {});
      setHealth(data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHealth();
    const interval = setInterval(fetchHealth, 300000); // Polling every 5 minutes
    return () => clearInterval(interval);
  }, []);

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-lg">
          <p className="text-red-700">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <div className="p-4 rounded-lg shadow-md w-full max-w-4xl">
        <h2 className="text-2xl font-bold mb-4 text-center">System Health</h2>
        {loading ? (
          <Loader />
        ) : (
          <>
            {Object.entries(health).map(([service, data]) => (
              <div
                key={service}
                className="flex justify-center mb-4"
                style={{ margin: "0 auto", maxWidth: "1200px", width: "100%" }}
              >
                <StatusCard
                  service={service}
                  status={data.status}
                  lastRun={data.lastRun || ""}
                  data={data.info || null} // Pass otherInfo for storage
                  errorInfo={data.error || null} // Pass errorInfo for storages
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

import React from "react";
import { Modal, Button } from "react-bootstrap";

const CallLogModal = ({ logData, showModal, setShowModal }) => {
  let parsedData;
  try {
    parsedData = JSON.parse(logData);
  } catch (error) {
    console.error("Error parsing logData JSON:", error);
    parsedData = "Invalid JSON data";
  }

  let rawTicketHTML = parsedData?.rawTicket || "";

  // Step 1: Remove "Attachment: [file].png, S3 URL: [url]" using regex
  rawTicketHTML = rawTicketHTML.replace(
    /Attachment:\s*[^,]+,\s*S3\s*URL:\s*https:\/\/[^\s]+/g,
    ""
  );

  rawTicketHTML = rawTicketHTML.replace(
    /Subject:\s*[^,]+,\s*From:\s*[^,]+,\s*Date:\s*[^,]+,\s*Body:\s*[^<]+/g,
    ""
  );

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>OOC Source File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {rawTicketHTML ? (
          <div dangerouslySetInnerHTML={{ __html: rawTicketHTML }} />
        ) : (
          <p>
            No ticket data is available for this call log. If the associated
            ticket is from YRF, please note that we are currently working on
            implementing OneCall File for YRF tickets.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowModal(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CallLogModal;

// import React from "react";
// import { Modal, Button } from "react-bootstrap";

// const CallLogModal = ({ logData, showModal, setShowModal }) => {
//   let parsedData;
//   try {
//     parsedData = JSON.parse(logData);
//   } catch (error) {
//     console.error("Error parsing logData JSON:", error);
//     parsedData = "Invalid JSON data";
//   }

//   const JsonObjectDisplay = ({ data, indentLevel = 0 }) => {
//     if (typeof data !== "object" || data === null) {
//       // Data is not an object or is null, render it directly
//       return <span>{JSON.stringify(data)}</span>;
//     }

//     const indentStyle = {
//       marginLeft: `${indentLevel * 20}px`,
//     };

//     return (
//       <div>
//         {Object.entries(data).map(([key, value]) => (
//           <div key={key} style={indentStyle}>
//             <strong>{key}:</strong>{" "}
//             {typeof value === "object" ? (
//               <JsonObjectDisplay data={value} indentLevel={indentLevel + 1} />
//             ) : (
//               JSON.stringify(value)
//             )}
//           </div>
//         ))}
//       </div>
//     );
//   };

//   return (
//     <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
//       <Modal.Header closeButton>
//         <Modal.Title>Call Log Details</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         {typeof parsedData === "string" ? (
//           <div>{parsedData}</div>
//         ) : (
//           <JsonObjectDisplay data={parsedData} />
//         )}
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={() => setShowModal(false)}>Close</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default CallLogModal;

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Col, Form, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dummyDoc from "../../Images/dummy-doc.webp";

import TicketAPIs from "../../APIs/ticket";
import { toast } from "react-toastify";

const DocumentModal = (props) => {
  const { unCompleteModal, ticketIds, userId } = props;
  // State for email fields
  const [to, setTo] = useState(props?.to);
  const [cc, setCc] = useState(props?.cc);
  const [subject, setSubject] = useState(props.subject);
  const [body, setBody] = useState("");

  const [ticketFiles, setTicketFiles] = useState([]);

  const [isSending, setIsSending] = useState(false);

  // #region Handle change functions for each field
  const handleToChange = (e) => setTo(e.target.value);
  const handleCcChange = (e) => setCc(e.target.value);
  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleBodyChange = (e) => setBody(e.target.value);
  const [selectedImages, setSelectedImages] = useState([]);

  // #region Handle Send Email
  const handleSendEmail = async () => {
    let sendmail;
    try {
      // Map selectedImages to their URLs
      const attachments = selectedImages
        .map((imageId) => {
          const file = ticketFiles.find((file) => file.id === imageId);
          return file ? file.presignedUrl : null; // Assuming you want to send the presignedUrl
        })
        .filter((url) => url !== null); // Filter out any non-found files (just in case)

      // Here you can use the state values to send an email
      setIsSending(true);
      sendmail = await TicketAPIs.sendEmail({
        ticketIds: ticketIds,
        userId: props?.userId,
        to: to,
        ccEmail: unCompleteModal ? "" : cc,
        subject: subject.toString(),
        body: body,
        attachments: attachments,
      });
      if (unCompleteModal) {
        if (ticketIds && userId) {
          const response = await TicketAPIs.uncompleteTicket({
            ticketIds: ticketIds,
            userId: userId,
          });
          if (response.data.success) {
            toast.success("Ticket uncompleted successfully!");
            window.location.reload();
          } else {
            toast.error("Ticket not uncompleted!");
          }
        }
      }
      if (sendmail.data.success) {
        toast.success("Email sent successfully!");
      }
    } catch (error) {
      if (!sendmail.data.success) {
        toast.error("Error in sending email!");
      }
      toast.error(`Error while uncompleting ticket`);
    } finally {
      setIsSending(false);
      props.onHide();
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleImageSelect = (imageId) => {
    const isSelected = selectedImages.includes(imageId);

    if (isSelected) {
      setSelectedImages(selectedImages.filter((id) => id !== imageId));
    } else {
      setSelectedImages([...selectedImages, imageId]);
    }
  };

  const getTicketFiles = async (ids) => {
    const tickets = await TicketAPIs.ticketFiles({
      ticketId: props.ticketIds,
    });

    if (tickets) {
      // we are sorting on basis on createdAt at priorty but if createdAt are same then we are sorting on base of fileName last _1, _2, _45, basis
      if (Array.isArray(tickets?.data?.data)) {
        const sortedData = tickets?.data?.data.sort((a, b) =>
          a?.createdAt.localeCompare(b?.createdAt)
        );
        setTicketFiles(sortedData);
      }
    }
    // }
  };

  useEffect(() => {
    setSubject(props.subject);
    setTo(props.to);
    setCc(props.cc);
    // setCc("auroralocates@aurora.ca");

    setBody(props.body);
    getTicketFiles(props.subject);
  }, [props.subject]);

  useEffect(() => {
    if (props.show) {
      setSelectedImages([]);
    }
  }, [props.show]);

  return (
    <Modal {...props} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h6 className="mb-0">{`${
            unCompleteModal
              ? "Send Email to Locator for Uncomplete Ticket"
              : "Send Documents to Contractor"
          }`}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="align-items-center mb-1">
            <Col sm="2">
              <b>To:</b>
            </Col>
            <Col sm="10">
              <Form.Group className="from-group">
                <Form.Control
                  type="email"
                  placeholder="example@gmail.com"
                  value={to}
                  onChange={handleToChange}
                />
              </Form.Group>
            </Col>
          </Row>
          {!unCompleteModal && (
            <Row className="align-items-center mb-1">
              <Col sm="2">
                <b>CC:</b>
              </Col>
              <Col sm="10">
                <Form.Group className="from-group">
                  <Form.Control
                    type="email"
                    value={cc}
                    onChange={handleCcChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row className="align-items-center mb-1">
            <Col sm="2">
              <b>Subject:</b>
            </Col>
            <Col sm="10">
              <Form.Group className="from-group">
                <Form.Control
                  type="text"
                  placeholder="Documents for Ticket No."
                  value={subject}
                  onChange={handleSubjectChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col sm="2">
              <b>Body:</b>
            </Col>
            <Col sm="10">
              <Form.Group className="from-group">
                <Form.Control
                  type="text"
                  placeholder=""
                  as={"textarea"}
                  rows={5}
                  value={body}
                  onChange={handleBodyChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        {!unCompleteModal &&
          ticketFiles.map((item) => (
            <div key={item.id} className=" slider-box">
              {item.name.endsWith(".png") ||
              item.name.endsWith(".PNG") ||
              item.name.endsWith(".jpg") ||
              item.name.endsWith(".jpeg") ? (
                <React.Fragment>
                  <Form.Check
                    type="checkbox"
                    className="mb-2"
                    label={`${item.name}`}
                    checked={selectedImages.includes(item.id)}
                    onChange={() => handleImageSelect(item.id)}
                  />
                  <div className="img-box" style={{ width: "300px" }}>
                    <img
                      src={item.presignedUrl}
                      alt={`img ${item.name}`}
                      style={{
                        height: "300px",
                        width: "300px",
                        objectFit: "contain",
                        objectPosition: "left",
                      }}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <div className="img-box">
                  <Form.Check
                    type="checkbox"
                    className="mb-2"
                    label={`Doc ${item.id}`}
                    checked={selectedImages.includes(item.id)}
                    onChange={() => handleImageSelect(item.id)}
                  />
                  <img
                    src={dummyDoc}
                    alt={`pdf ${item.id}`}
                    style={{
                      height: "200px",
                      margin: "auto",
                      borderRadius: "18px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}
            </div>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={isSending} onClick={handleSendEmail}>
          {`${
            isSending
              ? "Sending..."
              : `Send Email ${unCompleteModal ? "and Uncomplete" : ""}`
          }`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentModal;
